






























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { AgGridVue } from '@ag-grid-community/vue';
import { GridReadyEvent, SelectionChangedEvent, ColDef } from '@ag-grid-community/core';
import { sumBy } from 'lodash';
import OrderModel from '@/models/crm/order.model';
import OrderItemModel from '@/models/crm/order-item.model';
import { ArrayHelper } from '@/utils/helpers/array-helper';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { NumberHelper } from '@/utils/helpers/number-helper';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { GridHelper } from '@/utils/helpers/grid-helper';
import OrderService from '@/services/crm/order.service';
import { IKeyValue } from '@/interfaces/key-value.interface';

type DataGridFilterConfig = {
  keyword: string | undefined;
};

type FeaturedCard = {
  title: string;
  value: string | number;
  class?: string;
  cols: {
    lg: number | string;
    md: number | string;
  };
};

@Component({
  components: {
    AgGridWrapper,
    AgGridVue,
  },
})
export default class CrmOrderItemsList extends Vue {
  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'nomeProduto', sort: 'asc' }],
    columnDefs: this.columnDefs,
  };

  filters: DataGridFilterConfig = {
    keyword: undefined,
  };

  selected: OrderItemModel[] = [];

  @Prop({ required: true })
  readonly order!: OrderModel;

  get filteredItems(): OrderItemModel[] {
    let filteredItems = this.order.itens;

    if (this.filters.keyword) {
      const columnsToSearch = ['codProduto', 'nomeProduto'];

      filteredItems = ArrayHelper.filterByKeyword(filteredItems, columnsToSearch, this.filters.keyword);
    }
    return filteredItems;
  }

  get columnDefs(): ColDef[] {
    const columnDefs = [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.orderItemsList.grid.productCode')}`,
        field: 'codProduto',
        flex: 0.5,
        autoHeight: true,
      },
    ];
    if (this.hasColumn('codColecao')) {
      columnDefs.push({
        headerName: `${this.$t('crm.orderItemsList.grid.collectionCode')}`,
        field: 'codColecao',
        flex: 0.5,
        autoHeight: true,
      });
    }
    if (this.hasColumn('armazem')) {
      columnDefs.push({
        headerName: `${this.$t('crm.orderItemsList.grid.wareHouse')}`,
        field: 'armazem',
        flex: 0.4,
        autoHeight: true,
      });
    }
    if (this.hasColumn('operacao')) {
      columnDefs.push({
        headerName: `${this.$t('crm.orderItemsList.grid.operationType')}`,
        field: 'operacao',
        flex: 0.6,
        autoHeight: true,
      });
    }
    columnDefs.push(
      {
        headerName: `${this.$t('crm.orderItemsList.grid.productName')}`,
        field: 'nomeProduto',
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.orderItemsList.grid.requestedQuantity')}`,
        field: 'qtdePedida',
        type: 'rightAligned',
        maxWidth: 115,
      },
      {
        headerName: `${this.$t('crm.orderItemsList.grid.billingQuantity')}`,
        field: 'qtdeFaturado',
        type: 'rightAligned',
        maxWidth: 115,
      },
      {
        headerName: `${this.$t('crm.orderItemsList.grid.balanceQuantity')}`,
        field: 'qtdeSaldo',
        type: 'rightAligned',
        maxWidth: 115,
      },
      {
        headerName: `${this.$t('crm.orderItemsList.grid.canceledQuantity')}`,
        field: 'qtdeCancelado',
        type: 'rightAligned',
        maxWidth: 115,
      },
      {
        headerName: `${this.$t('crm.orderItemsList.grid.unitGrossValue')}`,
        field: 'valorUnitarioBruto',
        type: 'rightAligned',
        maxWidth: 130,
        valueFormatter: (params): string => NumberHelper.formatToLocaleDecimal(params.value),
      },
      {
        headerName: `${this.$t('crm.orderItemsList.grid.unitNetValue')}`,
        field: 'valorUnitarioLiquido',
        type: 'rightAligned',
        maxWidth: 130,
        valueFormatter: (params): string => NumberHelper.formatToLocaleDecimal(params.value),
      },
      {
        headerName: `${this.$t('crm.orderItemsList.grid.totalValue')}`,
        field: 'valorTotal',
        type: 'rightAligned',
        maxWidth: 130,
        valueFormatter: (params): string => NumberHelper.formatToLocaleDecimal(params.value),
      },
    );

    return columnDefs;
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: OrderItemModel[]): void {
    this.selected = selected;
  }

  onExport(selected: OrderModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.filteredItems.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Items Pedidos',
        fileName: OrderService.generateOrderItemsExportFilename(this.order, new Date()),
      });
    }
  }

  get featuredCards(): FeaturedCard[] {
    let alternatedCard: FeaturedCard = {
      title: `${this.$t('crm.orderItemsList.netTotal')}`,
      value: this.$n(this.order.totalLiquidoItens, 'currency'),
      cols: { lg: '', md: 6 },
    };

    if (this.order.totalSaldoItens > 0) {
      alternatedCard = {
        title: `${this.$t('crm.orderItemsList.issuedAndBalanceValue')}`,
        value: `${this.$n(this.order.totalFaturadoItens, 'currency')}/${this.$n(
          this.order.totalSaldoItens,
          'currency',
        )}`,
        cols: { lg: '', md: 6 },
      };
    }

    return [
      alternatedCard,
      {
        title: `${this.$t('crm.orderItemsList.grossTotal')}`,
        value: this.$n(this.order.totalBrutoItens, 'currency'),
        cols: { lg: '', md: 6 },
      },
      {
        title: `${this.$t('crm.orderItemsList.total')}`,
        value: this.$n(this.order.totalItens, 'currency'),
        class: 'text--primary',
        cols: { lg: '', md: 6 },
      },
      {
        title: `${this.$t('crm.orderItemsList.requestedItems')}`,
        value: this.order.qtdeItens,
        cols: { lg: 'auto', md: 6 },
      },
      {
        title: `${this.$t('crm.orderItemsList.invoiceItems')}`,
        value: this.order.qtdeItensFaturados,
        cols: { lg: 'auto', md: 6 },
      },
      {
        title: `${this.$t('crm.orderItemsList.canceledItems')}`,
        value: this.order.qtdeItensCancelados,
        cols: { lg: 'auto', md: 6 },
      },
    ];
  }

  get summary(): IKeyValue<number>[] | undefined {
    const items = this.filteredItems;

    if (!items || !items.length) {
      return undefined;
    }

    return [
      {
        qtdePedida: sumBy(items, 'qtdePedida'),
        qtdeFaturado: sumBy(items, 'qtdeFaturado'),
        qtdeSaldo: sumBy(items, 'qtdeSaldo'),
        qtdeCancelado: sumBy(items, 'qtdeCancelado'),
        valorTotal: sumBy(items, 'valorTotal'),
        valorUnitarioBruto: sumBy(items, 'valorUnitarioBruto'),
        valorUnitarioLiquido: sumBy(items, 'valorUnitarioLiquido'),
      },
    ];
  }

  hasColumn(columnName: string): boolean {
    const res = this.order.itens.find((item) => columnName in item);
    return res !== undefined && res !== null;
  }
}
